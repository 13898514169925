<template>
  <mdb-container fluid>
    <!-- Section: Logo -->
    <section class="p-3 pb-5">
      <mdb-row v-show="this.usergroup === 'trader'">
        <mdb-col xl="4" lg="6" class="mb-4">
          <mdb-card narrow>
            <mdb-view cascade gradient="blue">
              Einstellungen gelten für
            </mdb-view>
            <mdb-card-body cascade>
              <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">
                Veranstaltung
              </h5>
              <p class="mt-2">{{ model.event_label }}</p>
              <h5 class="grey-text align-text-bottom h-100">
                Ausgewählte Gruppe
              </h5>
              <mdb-select
                v-model="model.segment"
                ref="bulk_segment"
                @getValue="getSegmentSelectValue"
                search
              />
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col xl="4" lg="6" class="mb-4">
          <mdb-card narrow>
            <mdb-view cascade gradient="blue">
              Grafiken für diese Veranstaltung
            </mdb-view>
            <mdb-card-body cascade>
              <form id="images-form" ref="uploadForm"  novalidate @submit.prevent="saveImages" @keydown.enter.prevent>
                <section>
                  <mdb-row>
                    <mdb-col col="6" class="">
                      <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">Logo</h5>
                    </mdb-col>
                    <mdb-col col="6" class="">
                      <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.image_logo_url !== 'image_placeholder.svg'" @click="removeImage('logo')">Logo entfernen</mdb-btn>
                    </mdb-col>
                  </mdb-row> 
                  <mdb-row class="p-3">
                    <img id="logo-image" class="upload-image mb-3 img-fluid" :src="model.image_logo_url">
                  </mdb-row>
                  <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                    <input type="file" accept=".jpg, .jpeg, .png" @change="handleFileLogo( $event )"/>
                  </mdb-row>
                </section>
                <section v-show="this.usergroup === 'trader'">
                  <hr>
                  <mdb-row>
                    <mdb-col col="6" class="">
                      <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">E-Mail Header</h5>
                    </mdb-col>
                    <mdb-col col="6" class="">
                      <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.image_header_url !== 'image_placeholder.svg'" @click="removeImage('header')">Header entfernen</mdb-btn>
                    </mdb-col>
                  </mdb-row> 
                  <mdb-row class="p-3">
                    <img id="header-image" class="upload-image mb-3 img-fluid" :src="model.image_header_url">
                  </mdb-row>
                  <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                    <input type="file" accept=".jpg, .jpeg, .png" @change="handleFileHeader( $event )"/>
                  </mdb-row>
                </section>
                <section v-show="this.usergroup === 'trader'">
                  <hr>
                  <mdb-row>
                    <mdb-col col="6" class="">
                      <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">E-Mail Footer</h5>
                    </mdb-col>
                    <mdb-col col="6" class="">
                      <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.image_footer_url !== 'image_placeholder.svg'" @click="removeImage('footer')">Footer entfernen</mdb-btn>
                    </mdb-col>
                  </mdb-row> 
                  <mdb-row class="p-3">
                    <img id="footer-image" class="upload-image mb-3 img-fluid" :src="model.image_footer_url">
                  </mdb-row>
                  <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                    <input type="file" accept=".jpg, .jpeg, .png" @change="handleFileFooter( $event )"/>
                  </mdb-row>
                </section>
              </form>
            </mdb-card-body>
            <mdb-card-footer>
              <mdb-btn type="submit" form="images-form" color="blue" :disabled="loading === true" class="float-right">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
                Speichern
              </mdb-btn>
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <mdb-col xl="4" lg="6" class="mb-4">
          <mdb-card narrow v-show="this.usergroup === 'trader' || this.usergroup === 'organizer'">
            <mdb-view cascade gradient="blue">
              Grußformel
            </mdb-view>
            <mdb-card-body cascade>
              <form id="invitation-form" novalidate @submit.prevent="saveSettings" @keydown.enter.prevent>
                <mdb-input class="mt-3 mb-1" type="radio" id="greeting-1" name="greeting" radioValue="1" v-model="model.greeting" label="Sehr geehrte(r) {Anrede} {Titel} {Nachname}" />
                <mdb-input class="mb-3" type="radio" id="greeting-2" name="greeting" radioValue="2" v-model="model.greeting" label="Hallo {Vorname}" />
              </form>
            </mdb-card-body>
            <mdb-card-footer>
              <mdb-btn type="submit" form="invitation-form" color="blue" :disabled="loading === true" class="float-right">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
                Speichern
              </mdb-btn>
            </mdb-card-footer>
          </mdb-card>
          <mdb-card narrow class="mt-5" v-show="this.usergroup === 'trader'">
            <mdb-view cascade gradient="blue">
              Einladungsversand
            </mdb-view>
            <mdb-card-body cascade>
              <form id="invitation-form" novalidate @submit.prevent="saveSettings" @keydown.enter.prevent>
                <h5 class='grey-text mt-4'>E-Mail Text</h5>
                <mdb-input
                  label="Betreff"
                  type="text"
                  class="mb-3 mt-3"
                  v-model="model.invitation_mail_subject"
                />
                <vue-editor
                  id="invitationTextEditor"
                  :editor-toolbar="editorToolbar"
                  v-model="model.invitation_mail_text"
                />
              </form>
            </mdb-card-body>
            <mdb-card-footer>
              <mdb-btn type="submit" form="invitation-form" color="blue" :disabled="loading === true" class="float-right">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
                Speichern
              </mdb-btn>
            </mdb-card-footer>
          </mdb-card>
          <mdb-card narrow class="mt-5" v-show="this.usergroup === 'trader'">
            <mdb-view cascade gradient="blue">
              Ticketversand
            </mdb-view>
            <mdb-card-body cascade>
              <form id="ticket-form" novalidate @submit.prevent="saveSettings" @keydown.enter.prevent>
                <h5 class='grey-text mt-4'>E-Mail Text</h5>
                <mdb-input
                  label="Betreff"
                  type="text"
                  class="mb-3 mt-3"
                  v-model="model.ticket_mail_subject"
                />
                <vue-editor
                  id="ticketTextEditor"
                  :editor-toolbar="editorToolbar"
                  v-model="model.ticket_mail_text"
                />
                <h5 class='grey-text mt-4'>Ticket-PDF Text</h5>
                <mdb-input
                  label="Betreff"
                  type="text"
                  class="mb-3 mt-3"
                  v-model="model.ticket_pdf_subject"
                />
                <vue-editor
                  id="pdfTextEditor"
                  :editor-toolbar="editorToolbar"
                  v-model="model.ticket_pdf_text"
                />
              </form>
            </mdb-card-body>
            <mdb-card-footer>
              <mdb-btn type="submit" form="ticket-form" color="blue" :disabled="loading === true" class="float-right">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
                Speichern
              </mdb-btn>
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <mdb-col xl="4" lg="6" class="mb-4">
          <mdb-card narrow v-show="this.usergroup === 'organizer'">
            <mdb-view cascade gradient="blue">
              Accountversand
            </mdb-view>
            <mdb-card-body cascade>
              <form id="account-form" novalidate @submit.prevent="saveSettings" @keydown.enter.prevent>
                <h5 class='grey-text mt-4'>E-Mail Betreff</h5>
                <mdb-input
                  label="Betreff"
                  type="text"
                  class="mb-3 mt-3"
                  v-model="model.account_mail_subject"
                />
                <h5 class='grey-text mt-4'>E-Mail Text</h5>
                <vue-editor
                  id="accountFirstTextEditor"
                  :editor-toolbar="editorToolbar"
                  v-model="model.account_mail_text"
                />
                <h5 class='grey-text mt-4'>E-Mail Footer</h5>
                <vue-editor
                  id="accountSecondTextEditor"
                  :editor-toolbar="editorToolbar"
                  v-model="model.account_mail_text_footer"
                />
              </form>
            </mdb-card-body>
            <mdb-card-footer>
              <mdb-btn type="submit" form="account-form" color="blue" :disabled="loading === true" class="float-right">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
                Speichern
              </mdb-btn>
            </mdb-card-footer>
          </mdb-card>
          <mdb-card narrow v-show="this.usergroup === 'trader'">
            <mdb-view cascade gradient="blue">
              Angebote
            </mdb-view>
            <mdb-card-body cascade>
              <form id="pdf-form" ref="uploadPdfForm"  novalidate @submit.prevent="savePdfs" @keydown.enter.prevent>
                <section>
                  <mdb-row>
                    <mdb-col col="6" class="">
                      <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">Briefpapier für Angebots-PDF</h5>
                    </mdb-col>
                    <mdb-col col="6" class="">
                      <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.pdf_stationery_url !== 'image_placeholder.svg'" @click="removePdf('stationery')">Briefpapier entfernen</mdb-btn>
                    </mdb-col>
                  </mdb-row> 
                  <mdb-row class="p-3">
                    <iframe :src="model.pdf_stationery_url" v-show="model.pdf_stationery_url !== 'image_placeholder.svg'" width="100%" height="500px"/>
                  </mdb-row>
                  <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                    <input type="file" accept=".pdf" @change="handleFileStationery( $event )"/>
                  </mdb-row>
                </section>
                <section>
                  <h5 class='grey-text mt-4'>Einleitungstext für Angebots-PDF</h5>
                  <vue-editor
                    id="requestFirstTextEditor"
                    :editor-toolbar="editorToolbar"
                    v-model="model.request_pdf_text_introduction"
                  />
                  <h5 class='grey-text mt-4'>Abschlusstext für Angebots-PDF</h5>
                  <vue-editor
                    id="requestSecondTextEditor"
                    :editor-toolbar="editorToolbar"
                    v-model="model.request_pdf_text_conclusion"
                  />
                </section>
              </form>
            </mdb-card-body>
            <mdb-card-footer>
              <mdb-btn type="submit" form="pdf-form" color="blue" :disabled="loading === true" class="float-right">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
                Speichern
              </mdb-btn>
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <!-- /Section: Logo -->
  </mdb-container>
</template>

<script>
import { bus } from '../../main'
import {
  mdbBtn,
  mdbContainer,
  mdbRow,
  mdbCard,
  mdbCardBody,
  mdbCardFooter,
  mdbCol,
  mdbView,
  mdbInput,
  mdbSelect
} from 'mdbvue'
import axios from 'axios'
import { VueEditor } from "vue2-editor"

export default {
  name: 'Settings',
  components: {
    mdbBtn,
    mdbContainer,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbCardFooter,
    mdbCol,
    mdbView,
    mdbInput,
    mdbSelect,
    VueEditor
  },
  data () {
    return {
      loading: false,
      usergroup: localStorage.getItem('usergroup'),
      model: {
        segment: [],
        selected_group: 0,
        event_label: '',
        image_logo_url: 'image_placeholder.svg',
        image_header_url: 'image_placeholder.svg',
        image_footer_url: 'image_placeholder.svg',
        pdf_stationery_url: 'image_placeholder.svg',
        invitation_mail_subject: '',
        invitation_mail_text: '',
        ticket_mail_subject: '',
        ticket_mail_text: '',
        ticket_pdf_subject: '',
        ticket_pdf_text: '',
        request_pdf_text_introduction: '',
        request_pdf_text_conclusion: '',
        greeting: '1'
      },
      files: {
        logo: [],
        header: [],
        footer: [],
        stationery: []
      },
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ]
    }
  },
  created() {
    bus.$on('eventChange', this.eventChange)
  },
  beforeDestroy() {
    bus.$off('eventChange', this.eventChange)
  },
  mounted: function() {
    if (localStorage.usergroup) {
      this.usergroup = localStorage.usergroup
    }
    this.loadModel()
  },
  methods: {
    eventChange: function () {
      this.loadModel()
    },
    loadModel () {
      axios.get('get_settings.php?group=' + this.model.selected_group).then(response => {
        this.model = response.data
      })
    },
    handleFileLogo( event ){
      this.files.logo = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.logo)
      reader.onload = e =>{
          this.model.image_logo_url = e.target.result
      }
    },
    handleFileHeader( event ){
      this.files.header = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.header)
      reader.onload = e =>{
          this.model.image_header_url = e.target.result
      }
    },
    handleFileFooter( event ){
      this.files.footer = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.footer)
      reader.onload = e =>{
          this.model.image_footer_url = e.target.result
      }
    },
    handleFileStationery( event ){
      this.files.stationery = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.stationery)
      reader.onload = e =>{
          this.model.pdf_stationery_url = e.target.result + '#toolbar=0'
      }
    },
    saveSettings () {
      this.loading = true
      var url = 'edit_settings.php?group=' + this.model.selected_group
      axios({
        method: 'post',
        url: url,
        data: this.model
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          //this.loadModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    saveImages () {
      this.loading = true
      let formData = new FormData()
      formData.append('logo', this.files.logo)
      if (this.model.image_logo_url === 'image_placeholder.svg') {
        formData.append('delete_logo', true)
      } else {
        formData.append('delete_logo', false)
      }
      formData.append('header', this.files.header)
      if (this.model.image_header_url === 'image_placeholder.svg') {
        formData.append('delete_header', true)
      } else {
        formData.append('delete_header', false)
      }
      formData.append('footer', this.files.footer)
      if (this.model.image_footer_url === 'image_placeholder.svg') {
        formData.append('delete_footer', true)
      } else {
        formData.append('delete_footer', false)
      }
      axios.post( 'import_images.php',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.files.logo = []
          this.files.header = []
          this.files.footer = []
          this.$refs.uploadForm.reset()
          //this.loadModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    savePdfs () {
      this.loading = true
      let formData = new FormData()
      formData.append('stationery', this.files.stationery)
      if (this.model.pdf_stationery_url === 'image_placeholder.svg') {
        formData.append('delete_stationery', true)
      } else {
        formData.append('delete_stationery', false)
      }
      axios.post( 'import_images.php',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.files.stationery = []
          this.$refs.uploadPdfForm.reset()
          this.saveSettings()
          //this.loadModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    removeImage (type) {
      if (type === 'logo') {
        this.files.logo = []
        this.model.image_logo_url = 'image_placeholder.svg'
      }
      if (type === 'header') {
        this.files.header = []
        this.model.image_header_url = 'image_placeholder.svg' 
      }
      if (type === 'footer') {
        this.files.footer = []
        this.model.image_footer_url = 'image_placeholder.svg'
      }
      if (type === 'stationery') {
        this.files.stationery = []
        this.model.pdf_stationery_url = 'image_placeholder.svg'
      }
      this.$refs.uploadForm.reset()
    },
    removePdf (type) {
      if (type === 'stationery') {
        this.files.stationery = []
        this.model.pdf_stationery_url = 'image_placeholder.svg'
      }
      this.$refs.uploadPdfForm.reset()
    },
    getSegmentSelectValue(value) {
      if (this.model.selected_group != value) {
        this.model.selected_group = value
        this.loadModel()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #invitationTextEditor, #ticketTextEditor, #pdfTextEditor, #requestFirstTextEditor, #requestSecondTextEditor, #accountFirstTextEditor, #accountSecondTextEditor {
    height: 200px;
  }
  .file-upload-wrapper .single-preview img {
    width: auto !important;
    margin: 0 auto 0 auto !important;
  }
  .file-upload-wrapper .single-preview .detail {
    background-color: transparent;
  }
  .file-upload-wrapper .single-preview .detail-inner {
    visibility: hidden;
  }
  .file-upload-wrapper .message svg {
    display: block;
    margin: 0 auto 0 auto !important;
  }
  .upload-image {
    max-height: 200px;
  }
</style>
