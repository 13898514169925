<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
      >
        <e-columns>
          <e-column
            type='checkbox' width='50'
          ></e-column>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :isPrimaryKey="true"
            :visible="false"
          ></e-column>
          <e-column
            field="shorttag"
            headerText="Kürzel"
            textAlign="Left"
          ></e-column>
          <e-column
            field="label"
            headerText="Bezeichnung"
            textAlign="Left"
            :customAttributes="labelColumnAttributes"
          ></e-column>
          <e-column
            field="price_industry"
            headerText="Industriepreis"
            textAlign="Right"
            :allowSorting="false"
          ></e-column>
          <e-column
            field="price_trade"
            headerText="Handelspreis"
            textAlign="Right"
            :allowSorting="false"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Incidental -->
    <mdb-modal
      position="bottom"
      direction="bottom"
      size="fluid"
      full-height
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header>
        <mdb-modal-title v-if="modalEdit">Grundkosten bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Grundkosten hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="6" class="mb-3" style="border-right: 1px solid #aaa;">
              <mdb-row>
                <mdb-col md="6">
                  <legend><mdb-icon icon="cube" class="blue-text" /> Allgemein</legend>
                </mdb-col>
                <mdb-col md="6">
                  <mdb-btn @click.native="showEditor = true" size="sm" outline="blue" class="float-right"><mdb-icon icon="edit" fas/> Beschreibung</mdb-btn>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col class="mb-3">
                  <input type="hidden" v-model="model.id">
                  <mdb-input
                    label="Bezeichnung"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.label"
                    required
                  />
                  <mdb-input
                    label="Kürzel"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.shorttag"
                    required
                  />
                </mdb-col>
              </mdb-row>
            </mdb-col>
            <mdb-col md="6">
              <mdb-row>
                <mdb-col md="6">
                  <legend><mdb-icon icon="list-ul" class="blue-text" /> Preise</legend>
                </mdb-col>
              </mdb-row>
              <mdb-input
                label="Industriepreis"
                class="mb-3 mt-3"
                v-model="model.price_industry"
              />
              <mdb-input
                label="Handelspreis"
                class="mb-3 mt-3"
                v-model="model.price_trade"
              />
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="blue" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" v-if="modalEdit">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Speichern
        </mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" v-else>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Hinzufügen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Incidental -->
    <!-- Modal: Editor -->
    <mdb-modal
      centered
      removeBackdrop
      size="lg"
      direction="bottom"
      :show="showEditor"
      @close="closeEditor"
    >
      <mdb-modal-header>
        <mdb-modal-title>Beschreibung bearbeiten</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col md="12" class="mb-3">
            <mdb-row>
              <mdb-col class="mb-3">
                <vue-editor
                  id="descriptionEditor"
                  v-model="model.description"
                  :editor-toolbar="editorToolbar"
                />
              </mdb-col>
            </mdb-row>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="blue" @click.native="showEditor = false">Schließen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Editor -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDelete" @close="showDelete = false" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Grundkosten-Position löschen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählte Grundkosten-Position wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.label">
                      {{ record.label }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="danger" :disabled="loading === true" @click.native="deleteEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Löschen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDeleteAll" @close="showDeleteAll = false" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Alle Grundkosten, Werbeanbringungen und Produkte löschen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong class="red-text">Achtung:</strong><br>
              Da mit dem Löschen aller Grundkosten die systeminternen Verknüpfungen verloren gehen, werden zur Fehlervermeidung gleichzeitig sämtliche Werbeanbringungen und Produkte gelöscht.<br><br>Bitte laden Sie im Anschluss alle 3 XLS-Listen neu hoch, beginnend mit den Grundkosten.
            </p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showDeleteAll = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="danger" :disabled="loading === true" @click.native="deleteAll">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Löschen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
    <!-- Modal: importRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showImportRecords"
      @close="showImportRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Grundkosten importieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-import" fas size="4x" class="blue-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie eine Datei (Excel oder CSV) aus die importiert werden soll:
            </p>
            <p>
              <strong class="red-text">Achtung:</strong> Die Reihenfolge beim Upload ist zwingen einzuhalten.
              <ul>
                <li>1. Grundkosten</li>
                <li>2. Werbeanbringungen</li>
                <li>3. Produkte</li>
              </ul>
            </p>
            <mdb-input type="radio" id="import-option-1" name="importOptions" radioValue="gww" v-model="action.import" class="mb-2" label="GWW Format (Excel-Datei)" />
            <mdb-input type="radio" id="import-option-2" name="importOptions" radioValue="dmas" v-model="action.import" class="mb-4" label="DMAS Format (CSV-Datei)" />
            <input type="file" @change="handleFileUpload( $event )"/>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showImportRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="importRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Importieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: importRecords -->
    <!-- Modal: exportRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showExportRecords"
      @shown="exportRecordsShown"
      @close="showExportRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Grundkosten exportieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-export" fas size="4x" class="blue-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen Sie die Art der Grundkosten aus, die in eine Excel-Datei exportiert werden soll:
            </p>
            <mdb-input type="radio" id="export-option-1" name="exportOptions" radioValue="all" v-model="action.export" class="mb-2" label="Alle Grundkosten" />
            <mdb-input type="radio" id="export-option-2" name="exportOptions" radioValue="template" v-model="action.export" class="mb-2" label="Vorlage für Import" />
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <div class="mb-2 pl-4">Ausgewählte Grundkosten:</div>
                  <p v-show="this.action.export === 'all'" class="pl-4">{{ exportRecordCount }} Produkt(e)</p>
                  <p v-show="this.action.export === 'template'" class="pl-4">Vorlage für Import</p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showExportRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="exportRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Exportieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: exportRecords -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Resize, Toolbar, Sort, Search, Edit } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText
} from 'mdbvue'
import { VueEditor } from "vue2-editor"

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'Incidentals',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    VueEditor
  },
  data () {
    return {
      loading: false,
      model: {
        id: 'new',
        shorttag: '',
        label: '',
        description: '',
        price_industry: '',
        price_trade: ''
      },
      action: {
        selection: [],
        category: 'incidentals',
        import: 'gww',
        export: 'all'
      },
      selectedRecords: [],
      exportRecordCount: 0,
      showModal: false,
      showEditor: false,
      modalEdit: false,
      showDelete: false,
      showDeleteAll: false,
      showImportRecords: false,
      showExportRecords: false,
      deleteList: [],
      wasValidated: false,
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_incidentals.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Grundkosten hinzufügen', prefixIcon: 'e-add', id: 'addRecord' },
        { text: 'Bearbeiten', tooltipText: 'Grundkosten bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Löschen', tooltipText: 'Grundkosten löschen', prefixIcon: 'e-delete-4', id: 'deleteRecord' },
        { text: 'Alles Löschen', tooltipText: 'Alles löschen', prefixIcon: 'e-delete-4', id: 'deleteAll' },
        { type: 'separator' },
        { text: 'Import', tooltipText: 'Grundkosten importieren', prefixIcon: 'e-icon-upload', id: 'importRecords' },
        { text: 'Export', tooltipText: 'Grundkosten exportieren', prefixIcon: 'e-icon-export', id: 'exportRecords' },
        'Search'
      ],
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      sortSettings: {
        columns: [{
          field: 'label',
          direction: 'Ascending'
        }]
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      editableColumns: [
        {
          label: 'Industriepreis',
          field: 'price_industry'
        },
        {
          label: 'Handelspreis',
          field: 'price_trade'
        }
      ],
      editableRows: [],
      selectionOptions: {
        checkboxMode: 'Default'
      },
      labelColumnAttributes : {
        class: 'label-column'
      }
    }
  },
  created: function() {

  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    });
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    });
    this.resetModel()
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
  },
  computed: {
    exportAction() {
      return this.action.export;
    }
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
        }
      },
      deep: true
    },
    exportAction: {
      handler: function () {
        axios.get('get_export_count.php?category=' + this.action.category + '&action=' + this.action.export).then(response => {
          this.exportRecordCount = response.data
        })
      }
    }
  },
  methods: {
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['shorttag', 'label', 'price_industry', 'price_trade'])
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addRecord') {
        this.showModal = true
        this.modalEdit = false
      }
      if (args.item.id === 'editRecord') {
        axios.get('get_incidental.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
        this.showModal = true
        this.modalEdit = true
      }
      if (args.item.id === 'deleteRecord') {
        this.deleteList = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.deleteList.push(this.selectedRecords[i].id)
        }
        this.showDelete = true
      }
      if (args.item.id === 'deleteAll') {
        this.showDeleteAll = true
      }
      if (args.item.id === 'importRecords') {
        this.showImportRecords = true
      }
      if (args.item.id === 'exportRecords') {
        this.showExportRecords = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_incidental.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    closeModal () {
      this.showModal = false
      this.showEditor = false
      this.resetModel()
      this.$refs.grid.clearRowSelection()
    },
    closeEditor () {
      this.showEditor = false
    },
    saveModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      if (this.model.shorttag !== '' && this.model.label !== '') {
        this.loading = true
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_incidental.php'
        } else {
          url = 'add_incidental.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          this.loading = false
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            if (this.modalEdit === true) {
              var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id)
              this.$refs.grid.updateRow(rowIndex, this.model)
            } else {
              this.$refs.grid.refresh()
            }
            this.showModal = false
            this.resetModel()
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    deleteEntry () {
      this.loading = true
      var url = 'delete_incidental.php'
      axios({
        method: 'post',
        url: url,
        data: this.deleteList
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    deleteAll () {
      this.loading = true
      var url = 'delete_incidental_all.php'
      axios({
        method: 'post',
        url: url
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDeleteAll = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    handleFileUpload( event ) {
      this.file = event.target.files[0]
    },
    importRecords () {
      this.loading = true
      let formData = new FormData()
      formData.append('file', this.file)
      axios.post( 'import_incidentals.php?action=' + this.action.import,
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showImportRecords = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    exportRecordsShown () {
      axios.get('get_export_count.php?category=' + this.action.category + '&action=' + this.action.export).then(response => {
        this.exportRecordCount = response.data
      })
    },
    exportRecords () {
      var url = Vue.prototype.$serverUrl + 'api/export_incidentals.php?action=' + this.action.export
      window.open(url)
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.shorttag = ''
      this.model.label = ''
      this.model.description = ''
      this.model.price_industry = ''
      this.model.price_trade = ''
    }
  },
  provide: {
    grid: [Toolbar, Search, Resize, Sort, Edit]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.label-column {
  font-weight: normal;
  font-size: 16px;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.row .col-md-3 {
  height: 75px !important;
}

.price-data .table-add {
  float: left !important;
  font-size: 14px;
  margin-bottom: 0px !important;
}

.price-data tr td:nth-child(4), .price-data tr th:nth-child(4) {
  display: none;
}

.product-image {
  width: auto;
  max-width: 200px;
  max-height: 150px;
}

.image-column {
  width: 200px;
}

#descriptionEditor {
  height: 400px;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

</style>
